import gql from 'graphql-tag'

import { IFarmConst } from './interfaces'

export const farms: IFarmConst[] = [
  // {
  //   poolTitle: 'XTT/XSP 0.3%',
  //   rewardSymbol: 'WXDC',
  //   pool: '0xcee8759569d94c75ea2b21fd6b6633b733bee2cc',
  //   refundee: '0x359ad8918A16BF0dB048eCcDbdEE761548312693',
  //   startTime: 1700558088,
  //   endTime: 1700763048,
  //   vestingPeriod: 18000,
  //   rewardToken: '0x951857744785E80e2De051c32EE7b25f9c458C42',
  //   incentiveId: '0xa2d14d22fca79109f47241be4b56110542bc148dfa725d219fc779ac25224b68',
  // },
  // {
  //   poolTitle: 'WXDC/XSP 0.3%',
  //   rewardSymbol: 'WXDC',
  //   pool: '0xd8402e028eb18a492ebd7462c270c70c61fc85cf',
  //   refundee: '0x359ad8918A16BF0dB048eCcDbdEE761548312693',
  //   startTime: 1700745252,
  //   endTime: 1701112092,
  //   vestingPeriod: 180000,
  //   rewardToken: '0x951857744785E80e2De051c32EE7b25f9c458C42',
  //   incentiveId: '0x4917b33675ae0a4ece42243f2bb279f60d0c280926f517acc9bce5d93c7375fa',
  // },
  // {
  //   poolTitle: 'XTT/XSP 0.3%',
  //   rewardSymbol: 'WXDC',
  //   pool: '0xcee8759569d94c75ea2b21fd6b6633b733bee2cc',
  //   refundee: '0x359ad8918A16BF0dB048eCcDbdEE761548312693',
  //   startTime: 1700133276,
  //   endTime: 1700338276,
  //   vestingPeriod: 18000,
  //   rewardToken: '0x951857744785E80e2De051c32EE7b25f9c458C42',
  //   incentiveId: '0xd480a6535f3d354a5e225e2bdd45f36801ef7981f36f056e599def76ef32401a',
  // },
  // {
  //   poolTitle: 'WXDC/XSP 0.3%',
  //   rewardSymbol: 'WXDC',
  //   pool: '0xd8402e028eb18a492ebd7462c270c70c61fc85cf',
  //   refundee: '0x359ad8918A16BF0dB048eCcDbdEE761548312693',
  //   startTime: 1704571680,
  //   endTime: 1704596280,
  //   vestingPeriod: 180,
  //   rewardToken: '0x951857744785E80e2De051c32EE7b25f9c458C42',
  //   incentiveId: '0xd2c6851873cdbe6cc0463d5e8dd7254a88b46076fd67a58d91efbcab6bc74e01',
  // },
  // {
  //   poolTitle: 'WXDC/XSP 0.3%',
  //   rewardSymbol: 'WXDC',
  //   pool: '0xd8402e028eb18a492ebd7462c270c70c61fc85cf',
  //   refundee: '0x359ad8918A16BF0dB048eCcDbdEE761548312693',
  //   startTime: 1704628920,
  //   endTime: 1704650520,
  //   vestingPeriod: 180,
  //   rewardToken: '0x951857744785E80e2De051c32EE7b25f9c458C42',
  //   incentiveId: '0x8e826d093b070bf17dbb25ef1f5f32cde828e69a4e25985a5b3cbb6b7637ab29',
  // },
  // {
  //   poolTitle: 'WXDC/XSP 0.3%',
  //   rewardSymbol: 'XSP',
  //   pool: '0xd8402e028eb18a492ebd7462c270c70c61fc85cf',
  //   refundee: '0x359ad8918A16BF0dB048eCcDbdEE761548312693',
  //   startTime: 1704632100,
  //   endTime: 1704650520,
  //   vestingPeriod: 180,
  //   rewardToken: '0x36726235dadbdb4658d33e62a249dca7c4b2bc68',
  //   incentiveId: '0x24bb30115347d915bb08d052640d07db4e26f8b6a2dd77b6734f655f2d3c5d55',
  // },
  // ACTIVE
  {
    poolTitle: 'WXDC/XSP 0.3%',
    rewardSymbol: 'XSP',
    pool: '0xd8402e028eb18a492ebd7462c270c70c61fc85cf',
    refundee: '0x359ad8918A16BF0dB048eCcDbdEE761548312693',
    startTime: 1704660300,
    endTime: 1707338700,
    vestingPeriod: 86400,
    rewardToken: '0x36726235dadbdb4658d33e62a249dca7c4b2bc68',
    incentiveId: '0x2a5a5db057c5ff96d7c96e5fcb6a2a1abd843634afff2466c56d55424ca14d20',
  },
  {
    poolTitle: 'xUSDT/FXD 0.05%',
    rewardSymbol: 'xUSDT',
    pool: '0x56c4c4c6e86bef20f5ecad3807a793e3f1572b5b',
    refundee: '0x359ad8918A16BF0dB048eCcDbdEE761548312693',
    startTime: 1705510800,
    endTime: 1710694800,
    vestingPeriod: 1296000,
    rewardToken: '0xd4b5f10d61916bd6e0860144a91ac658de8a1437',
    incentiveId: '0xb39bffeefa7427a5922b887878420e130eb12164ac1c38d3584fc580dd5fb9cf',
  },
  {
    poolTitle: 'WXDC/PRNT 1%',
    rewardSymbol: 'PRNT',
    pool: '0x87d66705f5ad1121f27d59c309f552b0f410a82c',
    refundee: '0x359ad8918A16BF0dB048eCcDbdEE761548312693',
    startTime: 1706745600,
    endTime: 1714435200,
    vestingPeriod: 604800,
    rewardToken: '0x0E11710AAd67E7427CfbC12c353284C2e335F62c',
    incentiveId: '0x2d1a6aea6fef509a0004dbb86acd0dc95cc2dfea9a490a0da3bf5754f9a48c00',
  },
  {
    poolTitle: 'WXDC/PLI 0.3%',
    rewardSymbol: 'PLI',
    pool: '0x662c972560e2b9f5014d6d42721121b4a18af52a',
    refundee: '0x359ad8918A16BF0dB048eCcDbdEE761548312693',
    startTime: 1706184000,
    endTime: 1708862400,
    vestingPeriod: 432000,
    rewardToken: '0xff7412ea7c8445c46a8254dfb557ac1e48094391',
    incentiveId: '0x6a10475fdf8050ae11a9eb83292f03bd22e5d3b538e0a5367067f8aa91afb34c',
  },
  {
    poolTitle: 'SRX/FXD 0.3%',
    rewardSymbol: 'SRX',
    pool: '0x661f9be6f8aa966e8ae762e30933e4001bd24bb8',
    refundee: '0x359ad8918A16BF0dB048eCcDbdEE761548312693',
    startTime: 1705752000,
    endTime: 1708430400,
    vestingPeriod: 1296000,
    rewardToken: '0x5d5f074837f5d4618b3916ba74de1bf9662a3fed',
    incentiveId: '0x4b01784c26bbae7b2c0af5eb4f8c8140bb67690414b8651a814baf164a6e3662',
  },
  {
    poolTitle: 'WXDC/FXD 0.3%',
    rewardSymbol: 'WXDC',
    pool: '0x06d4e57fc2f4ccc57970fa77985f3cb55655f854',
    refundee: '0x359ad8918A16BF0dB048eCcDbdEE761548312693',
    startTime: 1707498000,
    endTime: 1715274000,
    vestingPeriod: 1296000,
    rewardToken: '0x951857744785E80e2De051c32EE7b25f9c458C42',
    incentiveId: '0xf923313f7efccaad87825b0ff63b90b71d5e6fedef09b41607a50962082c096e',
  },
  {
    poolTitle: 'CHUPA/WXDC 1%',
    rewardSymbol: 'CHUPA',
    pool: '0x19ffb8625408b50e2b6150213685da4e4d066803',
    refundee: '0x359ad8918A16BF0dB048eCcDbdEE761548312693',
    startTime: 1711990800,
    endTime: 1717174800,
    vestingPeriod: 432000,
    rewardToken: '0xCF071288cC70D737105c97485F9820668EAcCa51',
    incentiveId: '0xa1e58d9dae5bfa2cb6eb8a22e06a4e6901a1d3b34c0302ae0ec256b890537120',
  },
  {
    poolTitle: 'PLI/WXDC 0.3%',
    rewardSymbol: 'PLI',
    pool: '0x662c972560e2b9f5014d6d42721121b4a18af52a',
    refundee: '0x359ad8918A16BF0dB048eCcDbdEE761548312693',
    startTime: 1712581200,
    endTime: 1720357140,
    vestingPeriod: 432000,
    rewardToken: '0xff7412ea7c8445c46a8254dfb557ac1e48094391',
    incentiveId: '0xfb32edce835dac1c4c1e7fc9f09fc9a82423490a2e3e7f6c9e93a14e7fd1386c',
  },
]

export const STAKER_DETAILS = gql`
  query ps($ids: [String]) {
    incentives(first: 100, where: { id_in: $ids }) {
      id
      pool
      reward
      positions(first: 1000, where: { staked: true }) {
        position {
          id
        }
      }
    }
  }
`

export const STAKED_POSITIONS = gql`
  query positions($incentiveId: String, $owner: String) {
    incentives(where: { id: $incentiveId }) {
      positions(where: { position_: { owner: $owner }, staked: true }) {
        position {
          id
        }
      }
    }
  }
`

export const UNSTAKED_POSITIONS = gql`
  query positions($incentiveId: String, $owner: String) {
    incentives(where: { id: $incentiveId }) {
      positions(where: { position_: { owner: $owner }, staked: false }) {
        position {
          id
          owner
          liquidity
          stakes {
            id
            txHash
          }
          unstakes {
            id
          }
          claims {
            id
          }
        }
      }
    }
  }
`

export const POOLS_SEARCH = gql`
  query p($ids: [String]) {
    pools(where: { id_in: $ids }) {
      liquidity
      token1 {
        id
        derivedETH
      }
      token0 {
        id
        derivedETH
      }
      feeTier
      token0Price
      token1Price
      sqrtPrice
      tick
      totalValueLockedToken0
      totalValueLockedToken1
      totalValueLockedETH
      id
    }
  }
`

export const POSITIONS_SEARCH = gql`
  query p($ids: [String], $tick: String) {
    positions(where: { id_in: $ids, tickLower_: { tickIdx_lte: $tick }, tickUpper_: { tickIdx_gte: $tick } }) {
      id
      liquidity
      tickUpper {
        tickIdx
      }
      tickLower {
        tickIdx
      }
    }
  }
`
