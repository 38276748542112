import { BigNumber } from '@ethersproject/bignumber'
import { FeeAmount } from '@uniswap/v3-sdk/dist/constants'

export enum FetchStatus {
  INITIAL,
  PENDING,
  ERROR,
  SUCCESS,
}

export interface StatusData<T> {
  status: FetchStatus
  data: T
}

export interface IPool {
  liquidity: string
  token1: {
    id: string
    derivedETH: string
  }
  token0: {
    id: string
    derivedETH: string
  }
  token0Price: string
  token1Price: string
  sqrtPrice: string
  tick: string
  feeTier: FeeAmount
  totalValueLockedToken0: string
  totalValueLockedToken1: string
  totalValueLockedETH: string
  rewardTokenPrice: string // n * 1000
  id: string
}

export interface IIncentive {
  id: string
  pool: string
  reward: BigNumber
  positions: string[]
  stakedInXDC: BigNumber | null
}

export interface FarmState {
  pool: StatusData<{
    [id: string]: IPool
  }>
  incentive: StatusData<{
    [id: string]: IIncentive
  }>
}
