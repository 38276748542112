import { Currency, CurrencyAmount, Price, TradeType } from '@uniswap/sdk-core'
import { nativeOnChain } from 'constants/tokens'
import { Chain } from 'graphql/data/__generated__/types-and-hooks'
import { chainIdToBackendName, isGqlSupportedChain } from 'graphql/data/util'
import { INTERNAL_ROUTER_PREFERENCE_PRICE } from 'state/routing/slice'
import { TradeState } from 'state/routing/types'
import { useRoutingAPITrade } from 'state/routing/useRoutingAPITrade'
import { getNativeTokenDBAddress } from 'utils/nativeTokens'
import { SupportedChainId } from 'constants/chains'

import useStablecoinPrice from './useStablecoinPrice'
import { useCurrentTokenPrice } from 'graphql/thegraph/tokens/currentTokenPrice'

// ETH amounts used when calculating spot price for a given currency.
// The amount is large enough to filter low liquidity pairs.
const ETH_AMOUNT_OUT: { [chainId: number]: CurrencyAmount<Currency> } = {
  [SupportedChainId.XDC]: CurrencyAmount.fromRawAmount(nativeOnChain(SupportedChainId.XDC), 1e16),
  [SupportedChainId.XDC_TESTNET]: CurrencyAmount.fromRawAmount(nativeOnChain(SupportedChainId.XDC_TESTNET), 1e16),
  [SupportedChainId.MAINNET]: CurrencyAmount.fromRawAmount(nativeOnChain(SupportedChainId.MAINNET), 100e18),
  [SupportedChainId.ARBITRUM_ONE]: CurrencyAmount.fromRawAmount(nativeOnChain(SupportedChainId.ARBITRUM_ONE), 10e18),
  [SupportedChainId.OPTIMISM]: CurrencyAmount.fromRawAmount(nativeOnChain(SupportedChainId.OPTIMISM), 10e18),
  [SupportedChainId.POLYGON]: CurrencyAmount.fromRawAmount(nativeOnChain(SupportedChainId.POLYGON), 10_000e18),
  [SupportedChainId.CELO]: CurrencyAmount.fromRawAmount(nativeOnChain(SupportedChainId.CELO), 10e18),
}

function useETHValue(currencyAmount?: CurrencyAmount<Currency>): {
  data?: CurrencyAmount<Currency>
  isLoading: boolean
} {
  const chainId = currencyAmount?.currency?.chainId
  const amountOut = isGqlSupportedChain(chainId) ? ETH_AMOUNT_OUT[chainId] : undefined
  const { trade, state } = useRoutingAPITrade(
    TradeType.EXACT_OUTPUT,
    amountOut,
    currencyAmount?.currency,
    INTERNAL_ROUTER_PREFERENCE_PRICE
  )

  // Get ETH value of ETH or WETH
  if (chainId && currencyAmount && currencyAmount.currency.wrapped.equals(nativeOnChain(chainId).wrapped)) {
    return {
      data: new Price(currencyAmount.currency, currencyAmount.currency, '1', '1').quote(currencyAmount),
      isLoading: false,
    }
  }

  if (!trade || state === TradeState.LOADING || !currencyAmount?.currency || !isGqlSupportedChain(chainId)) {
    return { data: undefined, isLoading: state === TradeState.LOADING }
  }

  const { numerator, denominator } = trade.routes[0].midPrice
  const price = new Price(currencyAmount?.currency, nativeOnChain(chainId), denominator, numerator)
  return { data: price.quote(currencyAmount), isLoading: false }
}

export function useUSDPrice(currencyAmount?: CurrencyAmount<Currency | any>): {
  data: number | undefined
  isLoading: boolean
} {
  const chain = currencyAmount?.currency.chainId ? chainIdToBackendName(currencyAmount?.currency.chainId) : undefined

  // const currency = currencyAmount?.currency;
  const { data: ethValue, isLoading: isEthValueLoading } = useETHValue(currencyAmount)

  const addressToken = getNativeTokenDBAddress(chain ?? Chain.Xinfin)

  const {
    data: tokenData,
    // loading: fetchLoading,
    // error: errorLoading,
  } = useCurrentTokenPrice([addressToken])

  if (tokenData === undefined) {
    return {
      data: undefined,
      isLoading: false,
    }
  }
  const convertTokenData = Object.keys(tokenData).map(function (key) {
    return tokenData[key]
  })

  const ethUSDPrice = convertTokenData[0]?.priceUSD

  if (!ethUSDPrice || !ethValue) return { data: undefined, isLoading: isEthValueLoading }

  return { data: parseFloat(ethValue.toExact()) * ethUSDPrice, isLoading: false }
}
