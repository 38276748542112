import { AbiCoder } from 'ethers/lib/utils'

import { IncentiveKey } from './interfaces'
import { FeeAmount } from '@uniswap/v3-sdk'

const coder = new AbiCoder()
const typeArr = ['address', 'address', 'uint256', 'uint256', 'uint256', 'address']

export const encodeIncentive = (incentive: IncentiveKey): string => {
  const data = [
    incentive.rewardToken,
    incentive.pool,
    incentive.startTime,
    incentive.endTime,
    incentive.vestingPeriod,
    incentive.refundee,
  ]
  return coder.encode(typeArr, data)
}

export const decodeIncentive = (incentiveKey: string): IncentiveKey => {
  const arr = coder.decode(typeArr, incentiveKey)
  const res: IncentiveKey = {
    rewardToken: arr[0],
    pool: arr[1],
    startTime: arr[2],
    endTime: arr[3],
    vestingPeriod: arr[4],
    refundee: arr[5],
  }
  return res
}

export const convertToFeeTier = (feeTier: string): FeeAmount => {
  return Number(feeTier) === 100
    ? FeeAmount.LOWEST
    : Number(feeTier) === 500
    ? FeeAmount.LOW
    : Number(feeTier) === 3000
    ? FeeAmount.MEDIUM
    : FeeAmount.HIGH
}
