export const XDC_MAINLIST = 'https://raw.githubusercontent.com/pro100skm/xdc-token-list/master/mainnet.tokenlist.json'
export const XDC_TESTLIST = 'https://raw.githubusercontent.com/pro100skm/xdc-token-list/master/testnet.tokenlist.json'

export const UNSUPPORTED_LIST_URLS: string[] = []

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [XDC_MAINLIST, XDC_TESTLIST]

export const DEFAULT_INACTIVE_LIST_URLS: string[] = [...UNSUPPORTED_LIST_URLS]

// this is the default list of lists that are exposed to users
// lower index == higher priority for token import
export const DEFAULT_LIST_OF_LISTS: string[] = [
  ...DEFAULT_ACTIVE_LIST_URLS,
  ...DEFAULT_INACTIVE_LIST_URLS, // need to load dynamic unsupported tokens as well
]
