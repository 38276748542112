import { getAddress } from '@ethersproject/address'
import { BigNumber } from '@ethersproject/bignumber'
import { parseEther, parseUnits } from '@ethersproject/units'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Token } from '@uniswap/sdk-core'
import { Pool, Position } from '@uniswap/v3-sdk'
import { SupportedChainId } from 'constants/chains'
import { apolloClient, apolloStakerClient } from 'graphql/thegraph/apollo'
import { TokenAddressMap } from 'lib/hooks/useTokenList/utils'
import { POOLS_SEARCH, POSITIONS_SEARCH, STAKER_DETAILS } from 'pages/Farm/constants'
import { IFarmConst } from 'pages/Farm/interfaces'
import { convertToFeeTier } from 'pages/Farm/utils'

import { FarmState, FetchStatus, IIncentive, IPool } from './interfaces'

export const fetchPools = createAsyncThunk(
  'farm/fetchPools',
  async ({ farms }: { farms: IFarmConst[] }, { rejectWithValue }) => {
    const ids = farms.map((item) => item.pool)
    try {
      const {
        data: { pools },
        error,
      } = await apolloClient.query<{ pools: IPool[] }>({
        query: POOLS_SEARCH,
        variables: {
          ids,
        },
      })

      return pools
        .map((v) => {
          const { rewardToken } = farms.filter((farm) => farm.pool === v.id)[0]
          let rewardTokenPrice = '0'
          if (rewardToken.toLowerCase() === v.token1.id.toLowerCase()) {
            rewardTokenPrice = v.token1.derivedETH
          } else {
            rewardTokenPrice = v.token0.derivedETH
          }
          const rewardTokenPriceSplit = rewardTokenPrice.split('.')
          if (rewardTokenPriceSplit.length > 1) {
            rewardTokenPrice = (
              parseInt(rewardTokenPriceSplit[0]) * 1000 +
              parseInt(rewardTokenPriceSplit[1].slice(0, 3))
            ).toString()
          } else {
            rewardTokenPrice = (parseInt(rewardTokenPrice) * 1000).toString()
          }

          const feeTier = convertToFeeTier(v.feeTier as unknown as string)
          return { ...v, rewardTokenPrice, feeTier }
        })
        .reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {} as { [key: string]: IPool })
    } catch (e) {
      console.log(e)
      return rejectWithValue({ error: e })
    }
  },
  {
    condition: (_, { getState }) => {
      const { farm } = getState() as { farm: FarmState }
      return farm.pool.status !== FetchStatus.PENDING
    },
  }
)

export const fetchIncentives = createAsyncThunk(
  'farm/fetchIncentives',
  async (
    { ids, poolsMap, list }: { ids: string[]; poolsMap: { [id: string]: IPool }; list: TokenAddressMap },
    { rejectWithValue }
  ) => {
    try {
      const fullIncentives: {
        [id: string]: IIncentive
      } = {}
      const {
        data: { incentives },
      } = await apolloStakerClient.query<{
        incentives: { id: string; pool: string; reward: string; positions: { position: { id: string } }[] }[]
      }>({
        query: STAKER_DETAILS,
        variables: {
          ids,
        },
      })

      for (let i = 0; i < incentives.length; i++) {
        const incentive = incentives[i]

        const positionIds = incentive.positions.map((p: any) => p.position.id)

        fullIncentives[incentive.id] = {
          ...incentive,
          reward: parseUnits(incentive.reward, 0),
          positions: positionIds,
          stakedInXDC: null,
        }

        if (positionIds.length > 0) {
          const pool = poolsMap[incentive.pool]
          const {
            data: { positions: positionsData },
          } = await apolloClient.query<any>({
            query: POSITIONS_SEARCH,
            variables: {
              ids: positionIds,
              tick: pool.tick,
            },
          })

          const token0Address = getAddress(pool.token0.id)
          const token1Address = getAddress(pool.token1.id)
          const token0Info = list[SupportedChainId.XDC][token0Address].token.tokenInfo
          const token1Info = list[SupportedChainId.XDC][token1Address].token.tokenInfo

          const token0 = new Token(SupportedChainId.XDC, token0Address, token0Info.decimals)
          const token1 = new Token(SupportedChainId.XDC, token1Address, token1Info.decimals)

          fullIncentives[incentive.id]['stakedInXDC'] = positionsData.reduce((prev: BigNumber, cur: any) => {
            const positionPool: Pool = new Pool(
              token0,
              token1,
              pool.feeTier,
              pool.sqrtPrice.toString(),
              pool.liquidity.toString(),
              Number(pool.tick)
            )

            const position = new Position({
              pool: positionPool,
              liquidity: cur.liquidity.toString(),
              tickLower: Number(cur.tickLower.tickIdx),
              tickUpper: Number(cur.tickUpper.tickIdx),
            })

            const token0Price = parseEther(parseFloat(pool.token0.derivedETH).toString().slice(0, 9)).toString()
            const token1Price = parseEther(parseFloat(pool.token1.derivedETH).toString().slice(0, 9)).toString()
            return parseUnits(position.amount0.multiply(token0Price).toFixed(0), 0)
              .add(parseUnits(position.amount1.multiply(token1Price).toFixed(0), 0))
              .add(prev)
          }, BigNumber.from('0'))
        }
      }

      return fullIncentives
    } catch (e) {
      console.log(e)
      return rejectWithValue({ error: e })
    }
  },
  {
    condition: (_, { getState }) => {
      const { farm } = getState() as { farm: FarmState }
      return farm.incentive.status !== FetchStatus.PENDING
    },
  }
)
