import { useFilteredTokens, useSortedTokens } from "graphql/data/TopTokens"
import { CHAIN_ID_TO_BACKEND_NAME, CHAIN_NAME_TO_CHAIN_ID, unwrapToken } from "graphql/data/util"
import { Chain, TopTokens100Query } from "graphql/data/__generated__/types-and-hooks"
import { useMemo } from "react"
import { TokenData } from "state/tokens/types"
import { useWeb3React } from "@web3-react/core"
import { SupportedChainId } from "constants/chains"

export type TopToken = NonNullable<NonNullable<TopTokens100Query>['topTokens']>[number]

interface UseTopTokensReturnValue {
  tokens: TopToken[] | undefined
  tokenSortRank: Record<string, number>
}

export function fromTokensSubgraphToTokens100Query(topTokens: TokenData[], chain: Chain): TopTokens100Query['topTokens'] {
  const resultData: TopTokens100Query['topTokens'] = []
  topTokens.map(token => {
    resultData.push({
      id: token.address, 
      chain, 
      name: token.name,
      address: token.address, 
      symbol: token.symbol, 
      market: { 
        id: "marketId", 
        totalValueLocked: 
        { 
          id: token.address, 
          value: token.tvlUSD, 
        }, 
        price: { 
          id: token.address, 
          value: token.priceUSD, 
        }, 
        pricePercentChange: {
          id: token.address, 
          value: token.priceUSDChange 
        }, 
        volume: { 
          id: token.address, 
          value: token.volumeUSD , 
        } 
      }, 
      project: { 
        id: token.address, 
        logoUrl: undefined,  
      }
    })
  })

  return resultData
}

export function useTopTokensFromSubgraph(topTokens: TokenData[]): UseTopTokensReturnValue {
  // const chainId = CHAIN_NAME_TO_CHAIN_ID[chain]
  const {chainId} = useWeb3React()

  const existedChainId = chainId || SupportedChainId.XDC

  const unwrappedTokens = useMemo(() => topTokens?.map((token) => unwrapToken(existedChainId, token)), [chainId, topTokens])
  // from tokenData[] to TopTokens100Query['topTokens']
  const topTokensByUniswap = fromTokensSubgraphToTokens100Query(unwrappedTokens, CHAIN_ID_TO_BACKEND_NAME[existedChainId])
  const sortedTokens = useSortedTokens(topTokensByUniswap)
  const tokenSortRank = useMemo(
    () =>
      sortedTokens?.reduce((acc, cur, i) => {
        if (!cur.address) return acc
        return {
          ...acc,
          [cur.address]: i + 1,
        }
      }, {}) ?? {},
    [sortedTokens]
  )
  const filteredTokens = useFilteredTokens(sortedTokens)
  return useMemo(
    () => ({ tokens: filteredTokens, tokenSortRank }),
    [filteredTokens, tokenSortRank]
  )
}
